import { useState, useRef, useEffect, FC } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./PhotoCarousel.scss";
import NextArrow from "./NextArrow";
import PrevArrow from "./PrevArrow";
import { trackCarouselClick } from "../../helper/utils";

interface ImageData {
  image: string;
  label: string;
  name?: string;
  order: string;
  song: string;
  title: string;
}

interface PhotoCarouselProps {
  talentImages: ImageData[];
}

const PhotoCarousel: FC<PhotoCarouselProps> = ({ talentImages }) => {
  const [nav1, setNav1] = useState<Slider | undefined>(undefined);
  const [nav2, setNav2] = useState<Slider | undefined>(undefined);
  const [currentSlide, setCurrentSlide] = useState(0);

  const slider1 = useRef<Slider | null>(null);
  const slider2 = useRef<Slider | null>(null);

  useEffect(() => {
    setNav1(slider1.current ?? undefined);
    setNav2(slider2.current ?? undefined);
  }, []);

  const images = talentImages?.length ? talentImages : [];

  const handleSlideChange = (newIndex: number) => {

    const direction = newIndex > currentSlide ? "Left" : "Right";

    trackCarouselClick(direction, window.location.href, 'carousel', 'carousel');
    setCurrentSlide(newIndex);

  };

  const handleThumbnailClick = (index: number) => {
    const linkPosition = index.toString();
    trackCarouselClick('thumbnail_click', window.location.href, 'cta', 'thumbnail_block', linkPosition, 'click_content');

    setCurrentSlide(index);
    if (slider1.current) slider1.current.slickGoTo(index);

  };

  const mainSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: images.length > 1,
    fade: true,
    infinite: false,
    asNavFor: nav2,
    speed: 100,
    waitForAnimate: false,
    nextArrow: <NextArrow className="next" />,
    prevArrow: <PrevArrow className="prev" />,
    className: "custom-main-slider",
    beforeChange: (_oldIndex: number, newIndex: number) => handleSlideChange(newIndex),
  };

  const thumbSettings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    asNavFor: nav1,
    centerMode: images.length > 5,
    centerPadding: "0",
    dots: false,
    infinite: images.length > 5,
    swipeToSlide:true,
    speed: 100,
    waitForAnimate: false,
    className: "custom-thumbnail-slider",
    responsive: [
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: images.length > 5,
          dots: true,
          centerMode: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 1,
          infinite: images.length > 5,
          dots: true,
          slideToGo: 1,
          centerMode: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 1,
          infinite: images.length > 3,
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          initialSlide: 1,
          infinite: images.length > 3,
          dotsClass: "slick-dots slick-thumb-tablet",
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: images.length > 2,
        },
      },
      {
        breakpoint: 414,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          infinite: images.length > 2,
        },
      },
      {
        breakpoint: 390,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          centerMode: true,
          infinite: images.length > 2,
        },
      },
      {
        breakpoint: 375,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          centerMode: true,
          infinite: images.length > 2,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          centerMode: false,
          infinite: images.length > 2,
        },
      },
    ],
  };

  return (
    <div className="slider-container">
      <Slider {...mainSettings} asNavFor={nav2} ref={(slider) => (slider1.current = slider)}>
        {images.map((img, index) => (
          <div key={index}>
            <div className="text-image-container">
              <img
                className="slick-slide-image"
                src={img.image}
                alt={img.title || `Thumbnail ${index}`}
              />
              <div className="text-overlay">
                <p className="img-name">
                  Name: {img.name}
                </p>
                <p className="img-song">
                  Song: "{img.song}"
                </p>
              </div>
            </div>
            <div className="slid-image-desc">
              <span className="start-text">
              {img.label}
              </span>
              {(images.length > 1 ) && (<span className="end-text">
                {index + 1}/{images.length}
              </span>)}
            </div>
          </div>
        ))}
      </Slider>

      {(images.length > 1 ) && <Slider {...thumbSettings} asNavFor={nav1} ref={(slider) => (slider2.current = slider)}>
        {images.map((img, index) => (
          <div key={index} onClick={() => handleThumbnailClick(index)}>
            <img
              src={img.image} 
              alt={`Thumbnail ${index}`}
              height={120}
              width={200}
              style={{
                width: "200px",
                height: "120px",
                cursor: "pointer",
              }}
            />
          </div>
        ))}
      </Slider>}
    </div>
  );
};

export default PhotoCarousel;
