const APP_URL = process.env.REACT_APP_PUBLIC_URL

export const END_POINTS = {
  article: `${APP_URL}/api/article`,
  menus: `${APP_URL}/api/menus`,
  stories: `${APP_URL}/api/stories`,
  footer: `${APP_URL}/api/article/footer-content`,
  tags: `${APP_URL}/api/tag`,
  tagList: `${APP_URL}/api/tags`,
  talentShow: `${APP_URL}/api/carousel`,
}
