import axios from 'axios'
import { END_POINTS } from './domain'

export const GetCategory = async () => {
  try {
    let result = await axios.get(END_POINTS.article)
    return {
      data: result.data,
      error: false,
    }
  } catch (error) {
    return {
      error: true,
    }
  }
}

export const getArticleById = async (id: any, auth?: boolean) => {
  try {
    if (id) {
      let result = await axios.get(`${END_POINTS.article}/${id}`, { headers: { auth: auth } })
      if (!!result && result?.data && result?.data?.article) {
        return {
          data: result.data.article,
          error: false,
        }
      }
      return result
    }
  } catch (error) {
    return {
      error: true,
    }
  }
}

export const GetStoriesList = async () => {
  try {
    let result = await axios.get(`${END_POINTS.stories}`)

    return {
      data: result.data,
      error: false,
    }
  } catch (error) {
    return {
      error: true,
    }
  }
}

export const getTalentShowImages = async (id: any, auth?: boolean) => {
  try {
    if (id) {
      let result = await axios.get(`${END_POINTS.talentShow}/${id}`, { headers: { auth: auth } })
      if (!!result && result?.data) {
        const [carousel] = result.data?.carousel ?? {};
        return {
          data: carousel?.carousel_slider,
          error: false,
        }
      }
      return result
    }
  } catch (error) {
    return {
      error: true,
    }
  }
}
