import { FC } from "react";
import { ReactComponent as RightIcon } from "./assets/right-icon.svg";

interface PrevArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const PrevArrow: FC<PrevArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <RightIcon className="svg-nav-icon"/>
    </div>
  );
};

export default PrevArrow;