import { FC } from "react";
import { ReactComponent as LeftIcon } from "./assets/left-icon.svg";

interface NextArrowProps {
  className?: string;
  style?: React.CSSProperties;
  onClick?: () => void;
}

const NextArrow: FC<NextArrowProps> = ({ className, style, onClick }) => {
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <LeftIcon className="svg-nav-icon"/>
    </div>
  );
};

export default NextArrow;